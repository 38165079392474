<template>
  <div @click="toggleDialog(true)">
    <slot v-bind="{ toggleDialog }" />

    <lazy-growth-contact-dialog
      v-if="isDialogOpen"
      v-model="isDialogOpen"
      :email-only="emailOnly"
      :origin="origin"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { NewsletterSubscriptionOrigin } from "~/business-areas/analytics/analytics.model";

defineProps<{
  origin: NewsletterSubscriptionOrigin;
  emailOnly?: boolean;
}>();

defineEmits(["submit"]);

const [isDialogOpen, toggleDialog] = useToggle(false);
</script>
